<!-- 企业文化 -->
<template>
  <div class="company">
    <!-- <div class="row">
      <div
        class="col-xl-12 col-lg-12 item d-xl-flex"
        v-for="item in company"
        :key="item"
      >
        <div class="text-bar d-xl-flex">
          <div class="title">{{ item.title }}</div>
          <div class="text">
            <div class="h6">{{ item.subtitle }}</div>
            <p v-html="item.desc"></p>
          </div>
        </div>
        <div class="img-bar position-relative">
          <img
            :src="item.images[0]"
            class="left-img img-thumbnail"
            :class="{}"
          />
          <img :src="item.images[1]" alt="" class="mid-img img-thumbnail" />
          <img :src="item.images[2]" alt="" class="right-img img-thumbnail" />
        </div>
      </div>
    </div> -->
    <div class="row">
      <div class="mainBox"
      v-for="item in company"
      :key="item">
        <main class="main">
          <div class="images">
            <div class="image" v-for="image in item.images" :key="image">
              <img :src="image" alt="">
            </div>
            <div class="image img" v-show="item.images.length%2 == 1"></div>
          </div>
          <div class="texts">
            <h1>{{item.title}}: {{item.subtitle}}</h1>
            <div class="line"></div>
            <p v-html="item.desc"></p>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { getCompany } from '@/server/intoHuadu'
const company = ref(null)
onMounted(async () => {
  company.value = await getCompany()
})
</script>
<style lang="stylus" scoped>
.company{
  width 100%
  background #F7F7F7
  .row:last-child{
    margin-bottom 325px
  }
  .mainBox{
    width 100%
    height 520px
    background #F7F7F7
    @media screen and (max-width 960px) {
      height auto
    }
    .main{
      width 960px
      height 100%
      margin 0 auto
      @media screen and (max-width 960px) {
        width 100%
      }
      .images{
        width 100%
        height 50%
        @media screen and (max-width 960px) {
          height auto
        }
        display flex
        justify-content space-between
        align-content space-between
        flex-wrap wrap
        @media screen and (max-width 960px) {
          width 100%
          height auto
          justify-content space-around
        }
        .image{
          width 300px
          height 200px
          background #1C6195
          border-radius 5px
          margin-top 50px
          img{
            width 100%
            height 100%
            border-radius 5px
          }
        }
        .img{
          display none
          border none
          @media screen and (max-width 899px) {
            display inline-block
            border none
            opacity 0
          }
          @media screen and (max-width 599px) {
            display none
          }
        }
      }
      .texts{
        width 100%
        height 50%
        text-align center
        @media screen and (max-width 960px) {
          height auto
        }
        h1{
          margin-top 50px
          font-weight bold
          font-family MicrosoftYaHei-Bold
          font-size 40px
          @media screen and (max-width 768px) {
            font-size 25px
          }
        }
        .line{
          width 130px
          height 5px
          background #209BD7
          margin 10px auto 30px
          @media screen and (max-width 768px) {
            width 80px
          }
        }
        p{
          font-family MicrosoftYaHei
          text-align center
          font-size 16px
          @media screen and (max-width 768px) {
            font-size 14px
          }
        }
      }
    }
  }
  .mainBox:nth-of-type(2n){
    background #FFFFFF
  }
}
// .row
//   width 1170px !important
//   margin 0 auto
//   @media screen and (max-width 768px) {
//     width 100% !important
//     margin 0 auto
//   }
// .item
//   background #fff
//   height 520px
//   margin-bottom 30px
//   &:last-child
//     margin-bottom 0
//   @media screen and (max-width 768px) {
//     height auto
//     width 100%
//     flex-wrap nowrap
//     flex auto
//   }
  // .text-bar
  //   flex 2
  //   margin-right 20px
  //   padding 30px 44px 0 40px
  //   @media screen and (max-width 768px) {
  //     width 100%
  //     padding 10px 0 0 10px
  //   }
  //   .title
  //     line-height 64px
  //     padding 0 14px
  //     background #10366B
  //     font-size: 40px;
  //     font-family: Microsoft YaHei;
  //     font-weight: bold;
  //     color: #FFFFFF;
  //     height 64px
  //     margin-right 14px
  //   .text
  //     flex 1
  //     .h6
  //       font-size: 40px;
  //       font-family: Microsoft YaHei;
  //       font-weight: bold;
  //       color: #10366B
  //       line-height 1.15
  //       margin-bottom 25px
  //       margin-top 10px
  //     p
  //       font-size: 16px;
  //       font-family: Microsoft YaHei;
  //       font-weight: 400;
  //       color: #333333;
  //       line-height: 30px;
  // .img-bar
  //   flex 3
  //   @media screen and (max-width 768px) {
  //     width 100%
  //     flex auto
  //     min-height 250px
  //   }
  //   img
  //     object-fill cover
  //     position absolute
  //   .left-img
  //     width: 222px;
  //     height: 222px;
  //     background: #CDEFFF;
  //     border-radius: 5px;
  //     z-index 2
  //     top 206px
  //     right 460px
  //     @media screen and (max-width 768px) {
  //       width 100px
  //       height 100px
  //       top 20px
  //       left 0
  //     }
  //   .mid-img
  //     width: 362px;
  //     height: 362px;
  //     background: #CDEFFF;
  //     border-radius: 5px;
  //     z-index 1
  //     top 102px
  //     right 192px
  //     @media screen and (max-width 768px) {
  //       width 200px
  //       height 200px
  //       top 10px
  //       left 50%
  //       transform translateX(-50%)
  //     }
  //   .right-img
  //     width: 222px;
  //     height: 222px;
  //     background: #CDEFFF;
  //     border-radius: 5px;
  //     z-index 2
  //     top 266px
  //     right 42px
  //     @media screen and (max-width 768px) {
  //       width 100px
  //       height 100px
  //       bottom 20px
  //       right 0
  //       top auto
  //     }
  // &:nth-child(2n)
  //   flex-direction row-reverse
  //   .img-bar
  //     flex 4
</style>
