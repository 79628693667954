import { get } from './http'

export function getGroup() {
  // 集团概况
  return get('/api/singlepage/aboutus')
}

export function getOrganization() {
  // 组织架构
  return get('/api/singlepage/business')
}

export function getCompany() {
  // 企业文化
  return get('/api/singlepage/culture')
}

export function getHonour() {
  // 企业荣誉
  return get('/api/singlepage/honour')
}

export function getWorld() {
  // 社会公益
  return get('/api/singlepage/pubbenefit')
}
export function getDevelop() {
  // 社会公益
  return get('/api/singlepage/history')
}
